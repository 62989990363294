
import { Component, Vue, Prop, } from 'vue-property-decorator'

@Component({
  components: {},
  computed: {},
})
export default class CardFrameworksComponent extends Vue {
  @Prop({ required: false, default: false, }) readonly publish!: false
  @Prop({ required: false, default: false, }) readonly title!: string
  @Prop({ required: false, default: false, }) readonly controls!: number
  @Prop({ required: false, default: false, }) readonly categories!: number
}
