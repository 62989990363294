
import { Component, Vue, } from 'vue-property-decorator'
import CardFramework from '@/components/Frameworks/CardFramework.vue'
import CreateComponent from './CreateDialog.vue'
import DivisionFrameworkComponent from './DivisionInterns.vue'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    CardFramework,
    CreateComponent,
    DivisionFrameworkComponent,OverlayBackground
  },
  computed: {},
  head: {
    script: [{ type: 'application/javascript', src: '../js/analytics.js', },],
  },
})
export default class FrameworksComponent extends Vue {
  private dialogFramework = false
  private dialogDivision = false
  private loading = false;

  private Frameworks = this.$store.state.PolimorficModule.PolimorficState
  private frameworks = []

  mounted () {
    this.$mixpanel.track('Seccion Framework')
    this.getFrameworks()
  }

  private getFrameworks () {
    this.loading = true
    this.$store
      .dispatch('PolimorficModule/getFrameworks')
      .then((response) => {
        this.frameworks = this.Frameworks.frameworks
        this.$store.commit('PolimorficModule/SET_USER_FRAMEWORKS', response.data)
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}
